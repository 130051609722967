<template>
    <!-- <div class="no-overflow">
      <div class="marquee-container">
        <div v-html="text" :style="{ animation: animationStyles[0] }">
          
            
        </div>
        <div v-html="text" :style="{ animation: animationStyles[1] }">
          
            
        </div>
        <div v-html="text" :style="{ animation: animationStyles[2] }" >
          
            
        </div>
      </div>
    </div> -->
    <div></div>
  </template>
  
<!-- Accede a + de 52 cursos en línea - <span>¡Gran oportunidad!</span> -->

  <script>
  export default {
      props:['text'],
    data() {
      return {
        animationStyles: [
          'marquee 10s linear infinite',
          'marquee 10s linear infinite',
          'marquee 10s linear infinite',
        ],
      };
    },
  };
  </script>
  
  <style>
  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .no-overflow{
    overflow: hidden;
  }
  .marquee-container {
    transform: translateZ(0);
    overflow: hidden;
    white-space: nowrap;
    background-color: #ff4600;
    width: 300%;
    display: flex;
    
  }
  
  .marquee-container > div {
    will-change: transform, margin;
    flex-basis: 33%;
      display: inline-block;
      padding: 5px 10px;
      font-size: 8px;
      color: white;
      font-family: "Poppins-Regular";
    }
    .marquee-container > div span {
        font-size: 9px;
        font-family: "Poppins-Bold";
        
        
    }
    .marquee-container > div:last-child {
    margin-left: 10px; /* Puedes ajustar el valor según sea necesario */
}
  
    @media(min-width:300px){
        .marquee-container > div {
            font-size: 9px;
        }
        .marquee-container > div span {
            font-size: 10px;
            
        } 
    }
    @media(min-width:350px){
        .marquee-container > div {
            font-size: 11px;
        }
        .marquee-container > div span {
            font-size: 13px;
            
        } 
    }
    @media(min-width:400px){
        .marquee-container > div {
            font-size: 12px;
        }
        .marquee-container > div span {
            font-size: 14px;
            
        } 
    }
    @media(min-width:450px){
        .marquee-container > div {
            font-size: 14px;
        }
        .marquee-container > div span {
            font-size: 16px;
            
        } 
    }
    @media(min-width:500px){
        .marquee-container > div {
            font-size: 15px;
        }
        .marquee-container > div span {
            font-size: 17px;
            
        } 
    }
    @media(min-width:576px){
        .marquee-container > div {
            font-size: 17px;
        }
        .marquee-container > div span {
            font-size: 20px;
            
        } 
    }
    @media(min-width:768px){
        .marquee-container > div {
            font-size: 21px;
        }
        .marquee-container > div span {
            font-size: 23px;
            
        } 
    }
    @media(min-width:992px){
        .marquee-container > div {
            font-size: 20px;
        }
        .marquee-container > div span {
            font-size: 23px;
            
        } 
        .marquee-container {
            width: 200%;
        }
    }
    @media(min-width:1200px){
        .marquee-container > div {
            font-size: 25px;
        }
        .marquee-container > div span {
            font-size: 28px;
            
        } 
        
    }
    @media(min-width:1400px){
      
       
        .marquee-container > div {
            font-size: 30px;
        }
        .marquee-container > div span {
            font-size: 33px;
            
        } 
    }
    @media(min-width:1600px){
        .marquee-container > div {
            font-size: 33px;
        }
        .marquee-container > div span {
            font-size: 37px;
            
        } 
    }
    @media(min-width:1800px){
        .marquee-container > div {
            font-size: 34px;
        }
        .marquee-container > div span {
            font-size: 38px;
            
        } 
    }
  </style>
  